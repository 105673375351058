
   .carousel-section {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    /* background-color: #f9f9f9; */
    background-color: #f9f9f9;
  }
  
  .carousel-container {
    position: relative;
    width: 100%;
    max-width: 1250px;
    height: 500px; 
    display: flex;
    flex-direction: row;
   
    /* background-color: rgb(102, 102, 103); */
    background-color: rgb(37, 37, 38);
   
    box-shadow: 10px 10px 10px rgba(59, 59, 59, 0.1);
    border-radius: 10px;
    overflow: hidden;
    animation: slideAnimation 10s infinite;
  }
  
  .carousel-slide {
    display: flex;
    flex: 1;
    flex-direction: row;
    width: 100%;
    transition: transform 0.5s ease-in-out;
  }
  
  .carousel-content {
    flex: 1;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .carousel-content h2 {
    margin: 0;
    font-size: 30px;
    font-weight: bold;
    /* color: #333; */
    color: #FFF;
  }
  
  .carousel-content p {
    margin: 10px 0;
    font-size: 18px;
    /* color: #666; */
    color: #d3d0d0;
  }
  
  .buttons {
    margin-top: 20px;
  }
  
  .carousel-btn {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    margin-right: 10px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .carousel-btn:hover {
    background-color: #0056b3;
  }
  
  .carousel-image {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
 
    background-color: rgba(37, 37, 38, 0.852);
    /* background-color: rgb(102, 102, 103); */
   
  }
  
  .carousel-image img {
    max-width: 70%;
    max-height: 70%;
    object-fit: contain;
    border-radius: 0 10px 10px 0;
  }
  
  /* Responsive Styles */
  
  @media (max-width: 768px) {
    .carousel-container {
      flex-direction: column;
      align-items: center;
      height: auto; /* Adjust height for column layout */
    }
    .carousel-section {
     
      padding: 60px 10px;
    
    }
  
    .carousel-slide {
      flex-direction: column;
    }
  
    .carousel-content,
    .carousel-image {
      width: 100%;
    }
  
    .carousel-image {
      order: -1;
    }
  
    .carousel-image img {
      border-radius: 10px 10px 0 0;
    }
  }
  
  @media (max-width: 480px) {
    .carousel-content h2 {
      font-size: 20px;
      font-weight: bold;

   
    }
    .carousel-container{
      width: 100%;
      max-width: 380px;
      height: 400px;
    }
  
    .carousel-content p {
      font-size: 14px;
    }
    .carousel-section {
     
      padding: 60px 10px;
    
    }
  
   
  }
  