.requests {
    padding: 20px;
    background-color: #f9f9f9; /* Light background color for better contrast */
    border-radius: 8px; /* Rounded corners for a softer look */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  }
  
  .requests h3 {
    margin-bottom: 20px;
    font-size: 1.5rem;
    color: #333; /* Darker text color for better readability */
  }
  
  .requests table {
    width: 100%;
    border-collapse: collapse;
    background-color: #fff; /* White background for the table */
    border-radius: 8px; /* Rounded corners for the table */
    overflow: hidden; /* Ensures corners are rounded for table */
  }
  
  .requests th, .requests td {
    border: 1px solid #ddd;
    padding: 12px;
    text-align: left;
    font-size: 0.875rem; /* Slightly smaller font size for table text */
  }
  
  .requests th {
    background-color: #343535;
    color: white;
    font-weight: 600; /* Bold font for headers */
  }
  
  .requests tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  
  .requests tr:hover {
    background-color: #e0e0e0; /* Slightly lighter gray on hover */
  }
  
  .requests button {
    padding: 8px 12px;
    margin: 10px;
    border: none;
    border-radius: 4px; /* Rounded corners for buttons */
    color: white;
    cursor: pointer;
    font-size: 0.875rem;
    transition: background-color 0.3s ease; /* Smooth transition on hover */
  }
  
  .requests button:first-of-type {
    background-color: #4CAF50; /* Green for accept */
  }
  
  .requests button:first-of-type:hover {
    background-color: #45a049; /* Darker green on hover */
  }
  
  .requests button:last-of-type {
    background-color: #f44336; /* Red for reject */
  }
  
  .requests button:last-of-type:hover {
    background-color: #e53935; /* Darker red on hover */
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .requests table {
      font-size: 0.75rem; /* Smaller font size on smaller screens */
    }
  
    .requests button {
      padding: 6px 10px; /* Adjust button padding */
      font-size: 0.75rem; /* Smaller font size for buttons */
    }
  }
  