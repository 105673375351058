/* ModalStyles.css */
.oM-Modal {
    position: absolute;
    top: 50%;
    left: 50%;
    right: 50%;
    bottom: auto;
    /* margin-right: -50%; */
    transform: translate(-50%, -50%);
    background: white;
    padding: 20px;
    margin: 10px;
    width: 50%;
    border-radius: 10px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  }
  
  .oM-Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
  }
  
  .oM-modal-content {
    text-align: center;
    font-size: 10px;
  }
  
  .oM-modal-button {
    background-color: #2196F3;
    color: white;
    padding: 10px 20px;
    margin-top: 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .oM-modal-button:hover {
    background-color: #1976D2;
  }
  
  @media  (max-width: 500px) {
    .oM-Modal {
      width: 80%;
      left: 47%;
    }
  }