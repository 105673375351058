.signup-form-group {
  position: relative;
}

.password-input-container {
  position: relative;
}

.signup-input {
  width: 100%;
  padding: 10px;
  padding-right: 40px; 
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
}

.toggle-password-icon {
  position: absolute;
  right: 15px; /* Position it just next to the placeholder */
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 18px;
  color: #888;
}

.toggle-password-icon:hover {
  color: #333;
}
.signup-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 39px;
  height: 100vh;
  background-color: #f4f4f4;
  position: relative;
  justify-content: center; 
}

.back-button {
  position: absolute;
  top: 10px; 
  left: 10px;
  background-color: transparent;
  border: none;
  font-size: 1rem;
  color: #555;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.back-button svg {
  margin-right: 5px; 
}

.back-button:hover {
  color: #333;
}

.signup-container {
  background-color: white;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 350px; 
  margin-top: 20px; 
}

.signup-form {
  display: flex;
  flex-direction: column;
}

.signup-form-group {
  margin-bottom: 10px;
}

.signup-label {
  margin-bottom: 4px; 
  font-size: 12px;
}

.signup-title {
  font-size: 20px;
  text-align: center;
}

.signup-input {
  width: 100%;
  padding: 6px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 0.9rem; 
}

.signup-input::placeholder {
  font-size: 12px;
}

.signup-button {
  background-color: #02101D;
  color: white;
  padding: 8px; 
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 0.9rem; 
}

.signup-button:hover {
  background-color: #041f38;
}

.signup-error {
  color: red;
  font-size: 0.55rem; 
  margin-top: 3px;
  text-align: start;
}

.signup-success {
  color: green;
  font-size: 0.85rem; 
  margin-top: 10px;
}

.signup-text {
  text-align: center;
  margin-top: 10px;
  display: flex;
  justify-content: center;
}

.signup-text a {
  text-decoration: none;
  color: rgb(253, 182, 2);
  font-size: 0.85rem;
}

.signup-text a:hover {
  text-decoration: underline;
}
.otp {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 350px;
  margin-top: 20px;
}
.otp-form {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.otp-form h2 {
  font-size: 20px;
  margin-bottom: 15px;
  text-align: center;
}
.otp-form input {
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
}
.verify-button {
  background-color: #007bff;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.verify-button:hover {
  background-color: #0056b3;
}
.resend-otp {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
}

.resend-otp p {
  font-size: 12px;
  color: #555;
}

.resend-otp-link {
  background-color: transparent;
  border: none;
  color: #007bff;
  font-size: 12px;
  cursor: pointer;
}

.resend-otp-link:hover {
  text-decoration: underline;
}

.resend-status {
  margin-top: 5px;
  font-size: 12px;
  color: green;
  text-align: center;
}
.signup-success {
  font-size: 14px;
  color: green;
  margin-top: 10px;
  text-align: center;
}

.signup-error {
  font-size: 12px;
  color: red;
  margin-top: 5px;
  text-align: center;
}

.otp-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.verify-button {
  background-color: #02101D;
  color: white;
  padding: 8px; 
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 0.9rem;
}

.verify-button:hover {
  background-color: #041f38;
}

.resend-otp-link {
  background: none;
  border: none;
  color: #007bff;
  cursor: pointer;
}

.resend-otp-link:hover {
  text-decoration: underline;
}
.signup-text >p{
  font-size: 14px;
}

.error-text {
  color: red;
  margin-top: 8px;
  font-size: 13px;
  text-align: center;
}

.success-text {
  color: green;
  margin-top: 10px;
}

.signup-error {
  color: red;
  font-size: 0.6rem;
}
@media (max-width: 768px) {
  .signup-wrapper{
    padding-bottom: 163px;
  
  }
  .signup-container {
    margin-top: 30px;
    width: 85%;
    padding: 15px;
  }

  .back-button {
    top: 15px;
    left: 10px;
    font-size: 0.9rem;
  }

  .signup-button {
    font-size: 0.85rem;
  }
}

@media (max-width: 480px) {
  .signup-container {
    margin-top: 50px;
    width: 90%;
    padding: 15px;
  }

  .back-button {
    top: 10px;
    left: 5px;
    font-size: 0.8rem;
  }
}
