
.email-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f0f0f0; 
  }
  
  .email-form {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    width: 100%;
    text-align: center;
  }
  
  .email-form-group {
    margin-bottom: 20px;
  }
  
  .email-label {
    margin-bottom: 10px;
    text-align: left;
    font-size: 16px;
    font-weight: bold;
  }
  
  .email-input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
  }
  
  .email-submit-button {
    background-color: #02101D;
    color: white;
    border: none;
    padding: 10px 15px;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
    width: 100%;
  }
  
  .email-submit-button:hover {
    background-color: #041f38;
  }
  
  .success-message {
    color: green;
    font-size: 14px;
    margin-top: 10px;
  }
  
  .email-error {
    color: red;
    font-size: 12px;
    margin-top: 5px;
  }
  
  @media (max-width: 768px) {
    .email-form {
      max-width: 90%;
      padding: 15px;
    }
  }

.reset-password-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f0f0;
}

.reset-password-form {
  background-color: white;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 100%;
}

.reset-password-title {
  text-align: center;
  font-size: 24px;
  margin-bottom: 20px;
  color: #333;
}

.reset-password-form-group {
  margin-bottom: 20px;
}

.reset-password-label {
  margin-bottom: 5px;
}

.reset-password-input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.reset-password-input:focus {
  outline: none;
  border-color: #4A90E2;
  box-shadow: 0 0 5px rgba(74, 144, 226, 0.5);
}

.reset-password-button {
  width: 100%;
  padding: 12px;
  background-color: #02101D;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
}

.reset-password-button:hover {
  background-color: #041f38;
}

.success-message {
  color: green;
  text-align: center;
  margin-bottom: 10px;
}

.reset-password-error {
  color: red;
  text-align: center;
  margin-bottom: 10px;
}
