
.privacy-policy-container {
  display: flex;

  flex-direction: row;
  padding: 20px;
  background-color: #f4f4f4;
}

/* Sidebar styling */
.sidebar {
  position: sticky;
  top: 0;
  width: 250px;
  height: 100vh;
  padding: 20px;
  background-color: #333;
  color: white;
  overflow-y: auto;
}

.sidebar h2 {
  font-size: 20px;
  margin-bottom: 20px;
  text-align: center;
}
.section p {
  color: black;
}


.sidebar ul {
  list-style-type: none;
  padding-left: 0;
}

.sidebar li {
  margin-bottom: 10px;
}

.sidebar-link {
  color: white;
  text-decoration: none;
  font-size: 18px;
  display: block;
  padding: 8px;
  border-radius: 4px;
}

.sidebar-link:hover {
  background-color: #555;
}

/* Content styling */
.privacy-policy-content {
  flex: 1;
  padding-left: 20px;
  position: sticky; /* Make the content sticky */
  top: 0; /* Stick to the top of its container */
  height: 100vh; /* Take full height of the viewport */
  overflow-y: auto; /* Allow vertical scrolling */
  padding-right: 20px; /* Optional padding to avoid content sticking to the edge */
}

.privacy-policy-content h1 {
  font-size: 32px;
  margin-bottom: 20px;
}

.privacy-policy-content .date {
  font-size: 16px;
  margin-bottom: 20px;
}

.section {
  margin-bottom: 30px;
}

.section h2 {
  font-size: 28px;
  margin-bottom: 10px;
}

.section p {
  font-size: 18px;
  line-height: 1.6;
}

.section ul {
  list-style-type: disc;
  padding-left: 20px;
}

.section li {
  font-size: 18px;
  line-height: 1.6;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .privacy-policy-container {
    flex-direction: column;
  }

  .sidebar {
   display: none;
  }

  .sidebar h2 {
    font-size: 20px;
    text-align: left;
  }

  .sidebar-link {
    font-size: 16px;
    padding: 6px;
  }

  .privacy-policy-content {
    padding-left: 0;
  }

  .privacy-policy-content h1 {
    font-size: 28px;
  }

  .section h2 {
    font-size: 24px;
  }

  .section p {
    font-size: 16px;
  }

  .section ul {
    padding-left: 10px;
  }

  .section li {
    font-size: 16px;
  }
}

/* Styling for the sticky behavior */
.sidebar {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 20px;
  padding-top: 20px;
}


