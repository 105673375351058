.download-link {
  margin-top: 10px;
  color: white;
  text-decoration: underline;
}
.resume-button {
  display: inline-block;
  padding: 8px 12px;
  margin: 10px 5px;
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  color: #fff;
  background-color: #072c54; 
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  text-decoration: none; 
}
.resume-button:hover {
  background-color: #0056b3; 
}

.resume-button:active {
  transform: scale(0.98); 
}

.download-button {
  background-color: #28a745; 
}

.download-button:hover {
  background-color: #218838;
}

.resume-button span {
  display: inline-block;
  vertical-align: middle;
}

.resume-button .icon {
  margin-right: 8px; 
}

.admin-dashboard-container {
  padding: 20px;
  font-family: Arial, sans-serif;
}

.tabs-container {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.tab-button {
  padding: 10px 20px;
  margin: 0 10px;
  background-color: #f0f0f0;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.tab-button.active {
  background-color: #007bff;
  color: white;
}

.tab-button:hover {
  background-color: #007bff;
  color: white;
}

.filter-container {
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
}

.filter-input {
  padding: 8px;
  width: 100%;
  max-width: 250px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-right: 10px;
  transition: max-width 0.3s ease;
}

.table-wrapper {
  margin-top: 0px;
  padding: 30px;
}

.table-wrapper > h3 {
  text-align: center;
}

.custom-table {
  width: 100%;
  border-collapse: collapse;
}

.custom-table th,
.custom-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.custom-table td {
  font-size: 14px;
}

.custom-table th {
  background-color: #007bff;
  color: white;
}

.custom-table tr:hover {
  background-color: #f1f1f1;
}

.table-header-cell {
  text-align: left;
  padding: 10px;
  font-weight: bold;
}

.table-cell {
  padding: 10px;
}

.error-message-container {
  color: red;
  text-align: center;
}

.c-table-header-cell {
  width: 12%;
}

.query {
  width: 25%;
}

.filter-input {
  padding: 8px;
  width: 100%;
  max-width: 250px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-right: 10px;
}

@media (max-width: 768px) {
  .tabs-container {
    flex-direction: column;
    align-items: center;
  }

  .tab-button {
    margin: 10px 0;
    width: 100%;
    max-width: 250px;
  }

  .filter-input {
    width: 100%;
    max-width: 250px;
    margin: 10px 0;
  }

  .table-wrapper {
    padding: 15px;
  }

  .custom-table th,
  .custom-table td {
    font-size: 12px;
    padding: 6px;
  }

  .custom-table {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
  }

  .table-header-cell,
  .table-cell {
    padding: 8px;
    text-align: left;
  }

  .c-table-header-cell {
    width: auto;
  }

  .query {
    width: auto;
  }
}

@media (max-width: 480px) {
  .tabs-container {
    flex-direction: column;
  }

  .tab-button {
    width: 100%;
    margin: 5px 0;
  }

  .filter-input {
    width: 100%;
    margin: 5px 0;
  }

  .custom-table th,
  .custom-table td {
    font-size: 11px;
    padding: 4px;
  }

  .custom-table {
    font-size: 10px;
  }

  .table-header-cell,
  .table-cell {
    padding: 5px;
  }
}

