.sdcp-container {
    display: flex;
    flex-direction: row;
    padding: 20px;
    background-color: #f4f4f4;
  }
  
  /* Sidebar styling */
  .sdcp-sidebar {
    position: sticky;
    top: 20px; /* Keeps the sidebar 20px from the top */
    width: 250px;
    height: 100vh; /* Sidebar will take full height of the viewport */
    padding: 20px;
    align-items: center;
    text-align: center;
    background-color: #333;
    color: rgb(255, 255, 255);
    overflow-y: auto; /* Ensure the sidebar is scrollable if needed */
  }

  .sdcp-sidebar-heading{
    border: none;
    background-color: #333;
    color: white;
    font-size: 18px;
    padding: 8px ;
  }
  
  .sdcp-sidebar h2 {
    font-size: 20px;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .sdcp-sidebar ul {
    list-style-type: none;
    padding-left: 0;
  }
  
  .sdcp-sidebar li {
    margin-bottom: 10px;
  }
  
  .sdcp-sidebar-link {
    color: white;
    text-decoration: none;
    font-size: 18px;
    display: block;
    padding: 8px;
    border-radius: 4px;
  }
  
  .sdcp-sidebar-link:hover {
    background-color: #555;
  }
  
  /* Content styling */
  .sdcp-content {
    flex: 1;
    padding-left: 20px;
    height: 100vh; /* Content will take full height of the viewport */
    overflow-y: auto; /* Allow content to scroll independently */
    padding-right: 20px; /* Optional padding */
  }
  
  .sdcp-content h1 {
    font-size: 32px;
    margin-bottom: 20px;
  }
  .sdcp-intro{
    color: black;
  }
  .sdcp-paragraph{
    color: black;
  }
  
  .sdcp-content .date {
    font-size: 16px;
    margin-bottom: 20px;
  }
  
  .sdcp-section {
    margin-bottom: 30px;
  }
  
  .sdcp-section h2 {
    font-size: 28px;
    margin-bottom: 10px;
  }
  
  .sdcp-section p {
    font-size: 18px;
    line-height: 1.6;
  }
  
  .sdcp-section ul {
    list-style-type: disc;
    padding-left: 20px;
  }
  
  .sdcp-section li {
    font-size: 18px;
    line-height: 1.6;
  }
  .sdcp-sidebar-item{
    background-color:#333 ;
    color: white;
    border: none;
    font-size: 18px;
    display: block;
    text-align: center;
    padding: 8px;
  }
  
  /* Mobile responsiveness */
  @media (max-width: 768px) {
    .sdcp-container {
      flex-direction: column;
    }
  
    .sdcp-sidebar {
      display: none; /* Sidebar will not be visible on mobile */
    }
  
    .sdcp-sidebar h2 {
      font-size: 20px;
      text-align: left;
    }
  
    .sdcp-sidebar-link {
      font-size: 16px;
      padding: 6px;
    }
  
    .sdcp-content {
      padding-left: 0;
    }
  
    .sdcp-content h1 {
      font-size: 28px;
    }
  
    .sdcp-section h2 {
      font-size: 24px;
    }
  
    .sdcp-section p {
      font-size: 16px;
    }
  
    .sdcp-section ul {
      padding-left: 10px;
    }
  
    .sdcp-section li {
      font-size: 16px;
    }
  }
  
  /* Sticky sidebar */
  .sdcp-sidebar {
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 20px; /* Sticks to the top of its container */
    padding-top: 20px;
  }
  