/* Login Container */
.oM-login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: transparent;
  }
  
  /* Login Form */
  .oM-login-form {
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 30px;
    width: 100%;
    max-width: 400px;
    text-align: center;
  }
  
  /* Heading */
  .oM-login-heading {
    font-size: 32px;
    color: #2d2d2d;
    margin-bottom: 20px;
    font-weight: 700;
  }
  .oM-login-message.error {
    color: #d9534f;
    font-size: 13px;
}

.oM-login-message.success {
    color: #5bc0de;
}
  
  /* Form Fields */
  .oM-login-input-group {
    margin-bottom: 20px;
    text-align: left;
  }
  
  .oM-login-input-group label {
    display: block;
    font-size: 14px;
    color: #555;
    margin-bottom: 8px;
  }
  
  .oM-login-input-group input {
    width: 100%;
    padding: 8px;
    font-size: 16px;
    border: 2px solid #ddd;
    border-radius: 8px;
    outline: none;
    transition: border 0.3s ease;
  }
  
  .oM-login-input-group input:focus {
    border-color: #6c63ff;
  }
  
  /* Password Group */
  .oM-login-password-group {
    position: relative;
  }
  
  .oM-login-password-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px; /* Adding gap between the input and the button */
  }
  
  .oM-login-password-container input {
    width: 90%; /* Adjusting width of the input */
    padding: 8px;
    font-size: 16px;
    border: 2px solid #ddd;
    border-radius: 8px;
    outline: none;
  }
  
  .oM-login-password-toggle {
    background-color: transparent;
    border: none;
    color: #6c63ff;
    cursor: pointer;
    font-size: 14px;
    padding: 8px;
    margin-left: 8px;
  }
  
  /* Login Button */
  .oM-login-btn {
    width: 100%;
    padding: 12px;
    background-color: #000000;
    color: white;
    font-size: 16px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .oM-login-btn:hover {
    background-color: #5752e5;
  }
  
  /* Sign Up Link */
  .oM-login-signup-text {
    font-size: 14px;
    color: #555;
  }
  
  .oM-login-create-account {
    color: #ebcc07;
    cursor: pointer;
  }
  
  .oM-login-create-account:hover {
    text-decoration: underline;
  }
  
  /* Responsive Design */
  @media (max-width: 480px) {
    .oM-login-form {
      width: 90%;
      padding: 20px;
    }
  
    .oM-login-heading {
      font-size: 28px;
    }
  
    .oM-input-group input {
      padding: 10px;
    }
  
    .oM-login-btn {
      padding: 10px;
    }
  }
  