


  /* Style for the container that holds the slider */
.slider-container {
  display: flex;
  justify-content: center;
  overflow: hidden;
  padding: 20px;
  position: relative;
  width: calc(100% - 40px); /* 20px margin on each side */
  margin: 50px 20px;
  box-sizing: border-box;
  background-color: white;
}

/* Style for the sliding track (container for all slides) */
.slider-track {
  display: flex;
  animation: scroll 30s linear infinite; /* Infinite scrolling animation */
  /* gap: 10px; */
}

/* Animation for scrolling */

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-150px * 5)); 
  }
}

/* Style for individual slides (logos) */
.slide {
  flex: 0 0 auto; /* Prevent slides from stretching */
}

/* Style for the images within the slides */
.slide img {
  height: 100px; /* Set the logo height */
  object-fit: contain; /* Maintain aspect ratio */
  margin-left: 50px; /* Add some space between logos */
}
