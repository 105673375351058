body {
    margin: 0;
    padding: 0;
  }
  
  .admin-dashboard {
    display: flex;
  }
  
  .adminsidebar {
    width: 130px; /* Increase the width to fit icons and text horizontally */
    background-color: #000000;
    padding: 20px 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed; /* Make sidebar fixed */
    height: 100vh;
    overflow: hidden; /* Hide scrollbars */
  }
  
  .logo-container {
    color: white;
    font-weight: bold;
    margin-bottom: 20px; /* Add spacing below the logo */
  }
  
  .adminsidebar-icons-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
  }
  
  .adminsidebar-icon-wrapper {
    display: flex;
    align-items: center; /* Align items in a row */
    width: 100%;
    padding: 10px; /* Padding for clickable area */
    margin-bottom: 10px; /* Margin between icons */
    cursor: pointer;
    color: white;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .adminsidebar-icon-wrapper.selected {
    background-color: rgb(223,182,2);
    color: black;
  }

  
  .icon {
    margin-right: 10px; /* Space between icon and text */
  }
  
  .icon-label {
    color: rgb(255, 255, 255);
    font-size: 14px;
  }
  
  .adminsidebar-bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .content {
    margin-left: 150px; /* Offset the content to account for the fixed sidebar */
    flex-grow: 1;
    padding: 20px;
    overflow-y: auto; /* Allow vertical scrolling in the content area */
  }
  