.counter-row {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 60px;
  padding: 20px;
}

.counter {
  font-size: 2em;
  font-weight: 400;
  padding: 20px;
  border-radius: 8px;
  /* background-color: #eeebeb; */
  background-color:    #f9f9f9;

  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
}

.counter-value {
  font-size: 0.8em;
  color: #262926;
  margin-bottom: 8px;
}

.counter-title {
  font-size: 0.5em;
  color: #242424;
  text-align: center;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .counter-row {
    grid-template-columns: repeat(2, 1fr); 
    gap: 20px;
  }
}

@media (max-width: 480px) {
  .counter-row {
    grid-template-columns:repeat(2, 1fr); 
    gap: 20px;
  }
  .counter {
    font-size: 1.5em;
  }

}