/* src/compo/AdminProfile/AdminProfile.css */

.admin-profile-container {
    padding: 20px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    margin: 20px auto;
    text-align: center;
  }
  
  .profile-info {
    margin-bottom: 20px;
  }
  
  .profile-info p {
    font-size: 1rem;
    color: #333;
  }
  
  .logout-button {
    padding: 10px 20px;
    background-color: #dc3545;
    color: #ffffff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s, transform 0.2s;
  }
  
  .logout-button:hover {
    background-color: #c82333;
    transform: scale(1.05);
  }
  