.user-profile-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6); /* Slightly darker background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure the overlay is on top */
  }
  
  .user-profile {
    background: #fff;
    padding: 1.5rem;
    margin: 0px 10px;
    border-radius: 12px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    width: 400px; /* Adjusted width */
    display: flex;
    flex-direction: column;
    align-items: center; /* Center align content */
    position: relative; /* For positioning close button */
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border: none;
    font-size: 1.5rem;
    color: #333;
    cursor: pointer;
    transition: color 0.3s;
  }
  
  .close-button:hover {
    color: #007bff;
  }
  
  .avatar {
    margin-bottom: 1rem;
  }
  
  .profileAvatar {
    width: 50px; /* Adjusted size */
    height: 50px; /* Adjusted size */
    border-radius: 50%;
    object-fit: cover; /* Ensure the image fits well */
    border: 2px solid #007bff; /* Border around avatar */
  }
  
  .profile-details {
    text-align: center; /* Center text alignment */
 
  }
  
  .profile-details p {
    margin: 0.6rem 0;
    font-size: 0.8rem; /* Slightly smaller font size */
    font-weight: 500; /* Slightly bolder text */
    color: #333;
    padding: 0px 10px;
  }
  
  .profile-details strong {
    color: #007bff; /* Color for labels */
  }
  
  .logout-button {
    margin-top: 1rem;
    padding: 0.5rem 1rem;
    background: #dc3545;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    font-weight: bold;
    transition: background 0.3s;
  }
  
  .logout-button:hover {
    background: #c82333;
  }
  