/* .navbar{
    background-color: #02101d;
    
    }
    .navigation{
        color:rgb(228, 225, 225);
        
    }
    .nav-link {
        font-size: 18px;
        text-decoration: none;
        text-decoration-line: none;
        
    }
    
    .navbar-logo-toggle-icon .navbar-brand {
        padding-left: 20px;
        color: rgb(228, 225, 225);
    }
    .navbar-logo-toggle-icon .navbar-brand:hover{
      padding-left: 20px;
      color:  rgb(253,182,2) ;;
  }
    .navbar {
        padding: 10px 20px;
    }
    
    .navbar-toggler {
        border: none;
    }
    

  .profile-icon {
    color: #007bff;
    cursor: pointer;
  }
  
  .dropdown-menu {
    right: 0;
  }
.disabled-link {
    pointer-events: none;
    opacity: 0.5;
    cursor: not-allowed;
  }
.alert {
    margin-top: 60px; 
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1050;
  }
  .nav-item > button{
    color: rgb(228, 225, 225);
    
  }
  .nav-item > button:hover{
    color: rgb(228, 225, 225);
  }
  .modal-body {
    padding: 2rem;
  }
  
  .modal-body h4 {
    margin-bottom: 1rem;
    color: #333;
  }
  
  .modal-body p,
  .modal-body input {
    margin-bottom: 1rem;
  }
  
  .btn-primary {
    background-color: #007bff;
    border-color: #007bff;
  }
  
  .btn-primary:hover {
    background-color: #0056b3;
    border-color: #004085;
  }
  
  .modal-dialog-centered {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .profile-icon{
    color: rgb(228, 225, 225);
  }
 
.login-modal .modal-dialog.modal-sm {
    max-width: 400px; 
}
.login-modal .modal-content {
    height: 540px; 
    margin: auto; 
    display: flex;
    justify-content: center;
    padding-top: 0px;
}

.login-modal .modal-body {
    padding: 1rem;
    display: flex;
    justify-content: center;
    height: 100%;             
    flex-direction: column;    
    justify-content: flex-start;
    
}

.login-modal .modal-header {
    padding: 0.5rem;
    display: flex;
    justify-content: flex-end; 
    align-items: center;
    border-bottom: none;
    margin-top: 40px;
}

.login-modal .btn-close {
    font-size: 1 rem;
    color: #333; 
    border: none;
    cursor: pointer;
    margin-left: 330px;
}
.signup-modal .modal-dialog{
    max-width: 400px;
}

.logout-btn{
    background-color: #02101d;
    padding: 5px;
    color: white;
}
.logout-btn:hover{
    background-color:#041f38 ;
}

.logout-btn {
    background-color: #dc3545;
    color: #fff;
    border: none;
    padding: 8px 16px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    margin-right: 10px;
  }
  
  .logout-btn:hover {
    background-color: #c82333;
  }
  
  .remove-image-btn {
    background-color: #ffc107;
    color: #fff;
    border: none;
    padding: 8px 16px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .remove-image-btn:hover {
    background-color: #e0a800;
  }


 

    @media(max-width: 480px){
        .navbar{
            width: 100%;
        }
        #toggler-button{
            background-color:white ;
            border: none;
        }
        #toggler-button button:hover{
            border: none;
    
        }
        .Fabars{
          margin-left: 50px;
        }
    .navbar-logo-toggle-icon{
        display: flex;
        flex-direction: row;
        justify-content:space-between;
        background: none;
    }
    .navbar-brand{
        margin-left: -20px;
    }
    
    
    .navbar-toggler{
        margin-left: 180px;
        border: transparent;
    }
    button:hover{
        background-color: none;
        border: none;
    }
    .navbar-collapse{
        border: none;
    }
    }
    @media(max-width: 900px){
        .navbar{
            width: 100%;
        }
    .navbar-logo-toggle-icon{
        display: flex;
        flex-direction: row;
        justify-content:space-between;
        background-color: none;
    }
    .navbar-brand{
        margin-left: -20px;
    }
    }
  


     */



     /* General Modal Styles */

.logo-img{
  width: 80px;
  height: 80px;
}

.modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-dialog {
  max-width: 35%;
  margin: 1.75rem auto;
}

.modal-content {
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

/* Modal Header */
.modal-header {
  border-bottom: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin: 0;
}

.btn-close {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

/* Modal Body */
.modal-body {
  text-align: center;
  padding: 2rem;
}

.profile-image {
  cursor: pointer;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  object-fit: cover;
  margin-bottom: 1rem;
}

.logout-btn {
  background-color: #dc3545;
  color: #fff;
  border: none;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 4px;
  margin-top: 1rem;
}

.logout-btn:hover {
  background-color: #c82333;
}

.login-data {
  font-weight: bold;
}

.active-tab {
  color: #fff;
  font-weight: bold;
  text-shadow: 0 0 5px rgba(255, 255, 255, 0.7), 0 0 10px rgba(255, 255, 255, 0.5);
}


.navbar-links-container Link, .navbar-links-container .navigation Link {
  color: white;
  text-decoration:dashed;
  
  padding: 10px 8px;
  transition: color 0.3s ease-in-out;
}
.navbar-links-container button{
  color: white;
  text-decoration: none;
  padding: 10px 8px;
  margin-right: 32px;
  transition: color 0.3s ease-in-out;
  color: rgb(247, 243, 243);
  font-size: 1.1rem;
}
.navbar-links-container button:hover{
  color: #ffcc00;
}

.navbar-links-container a:hover, .navbar-links-container .navigation:hover {
  color: #ffcc00;
}
.drawer-links-container .drawer-link {
  color: white;
  text-decoration: none;
  padding: 10px 15px;
}

.drawer-links-container .drawer-link:hover {
  color: #ffcc00;
}


/* Responsive Styles */
@media (max-width: 576px) {
  .modal-dialog {
    margin: 1rem;
    max-width: 90%;
    height: 90%;
  }

  .modal-body {
    padding: 1rem;
  }

  .modal-title {
    font-size: 1.25rem;
  }

  .profile-image {
    width: 60px;
    height: 60px;
  }

  .logout-btn {
    padding: 0.4rem 0.8rem;
    font-size: 0.875rem;
  }
}
.btn-close-black {
  background: none;
  border: none;
  width: 1em;
  height: 1em;
  opacity: 1;
}

.btn-close-black:before {
  content: "×";
  font-size: 1.5em;
  color: black;
  position: absolute;
  top: 10%;
  left: 92%;
  transform: translate(-50%, -50%);
}

.btn-close-black:hover {
  color: red;
}


     
  nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 60px;
    background-color: #02101d;
  }

 .navbar-brand {
    padding-left: 20px;
    color: rgb(228, 225, 225);
}
.navbar-brand:hover{
  padding-left: 20px;
  color:  rgb(253,182,2) ;;
}
  .navbar-menu-container {
    display: none;
  }
  .navbar-links-container a {
    margin-right: 2rem;
    text-decoration: none;
    color: rgb(247, 243, 243);
    font-size: 1.1rem;
    font-weight: 600;
  }
  .primary-button {
    padding: 0.9rem 1.75rem;
    background-color: white;
    outline: none;
    border: none;
    border-radius: 5rem;
    font-size: 1.1rem;
    cursor: pointer;
    font-weight: 600;
    transition: 0.2s;
  }
  .navbar-cart-icon {
    font-size: 1.15rem;
  }
  .primary-button:hover {
    background-color: rgb(234, 234, 234);
  }
  .navbar-menu-container svg {
    
    font-size: 1.5rem;
    cursor: pointer;
  }

  @media (max-width: 1000px) {
    .navbar-links-container a {
      margin-right: 1rem;
      font-size: 1rem;
    }
    .primary-button {
      font-size: 1rem;
    }
    .loginSignup{
      font-size: 1rem;
    }
    .navbar-links-container Button{
      font-size: 1rem;
      padding: 0px;
      margin-right:5px ;
    }
  
  }

  @media (max-width: 800px) {
    .nav-logo-container {
      max-width: 140px;
    }
    .navbar-links-container {
      display: none;
    }
    .navbar-menu-container {
      display: flex;
    }
  }