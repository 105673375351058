.content-wrapper {
    max-width: 1200px; 
    margin: 0 auto; 
    padding: 40px;
}

.testimonial-section {
    margin: 20px 0;
}

@media (max-width: 768px) {
    .content-wrapper {
        padding: 10px; 
    }

    .header-testimonial h2 {
        font-size: 1.5rem; 

    }
    .testimonial-head{
        margin-bottom: 30px;
    }
}
