

   .ourteam-main-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }
  
  .ourteam-title {
    font-size: 2rem;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .ourteam-highlight {
    color: #ff6347;
  }
  
  .ourteam-container {
    width: 100%;
    max-width: 1200px;
  }
  
  .ourteam-leadership, .ourteam-management, .ourteam-team {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
    margin-bottom: 40px;
  }
  
  .ourteam-management {
    grid-template-columns: repeat(2, 1fr);
  }
  
  .ourteam-team {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
  
  .ourteam-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .ourteam-image {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 10px;
  }
  
  .ourteam-name {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 5px;
    text-align: center;
  }
  
  .ourteam-role {
    font-size: 1rem;
    color: #666;
    text-align: center;
  }
  
  /* Responsive styles */
  @media (max-width: 768px) {
    .ourteam-name{
      font-size: 1rem;

    }
    .ourteam-role {
      font-size: 0.8rem;
    }
    .ourteam-management {
      grid-template-columns: 1fr;
    }
  
    .ourteam-team {
      grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    }
  }
  