.login-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f4f4f4;
  padding: 20px; 
}
.toggle-password-icons {
  position: absolute;
  right: 28px; /* Position it just next to the placeholder */
  top: 48%;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 18px;
  color: #888;
}

.login-container {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 350px; 
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); 
  margin: auto;
  position: relative;
  z-index: 1;
}

.login-title {
  /* margin-bottom: 2px; */
  color: #333;
  font-size: 22px; 
  text-align: center;
  font-weight: 600; 
}
.password-container {
  display: flex;
  align-items: center;
}
.password-container .toggle-password {
  margin-left: 10px;
  cursor: pointer;
  background: none;
  border: none;
  color: #2e2e2f;
  font-size: 1em;
  outline: none;
}

.login-form {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.login-input {
  width: 100%;
  padding: 10px; 
  /* margin-bottom: 15px;  */
  border-radius: 5px;
  border: 1px solid #ced4da;
  font-size: 14px;
  transition: border-color 0.3s ease-in-out;
}
.login-form-group{
  margin-bottom: 15px;
}
.login-form-group > label{
  font-size: 14px;
}
.login-input:focus {
  border-color: #80bdff;
  outline: none;
  box-shadow: 0 0 8px rgba(0, 123, 255, 0.25);
}

.login-button {
  padding: 12px;
  background-color: #02101D;
  color: white;
  font-size: 16px;
  border: none;
  border-radius: 5px; 
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.login-button:hover {
  background-color: #041f38;
}

.error-message {
  color: #c72030;
  font-size: 10px;
}

.success-message {
  color: green;
  font-size: 10px;
  margin-top: 10px;
  text-align: center;
}

.login-links {
  text-align: center;
  margin-top: 15px; 
}
.login-links >p{
  font-size: 14px;
}
.forgot-password {
  color: rgb(253, 182, 2);
  cursor: pointer;
  margin-bottom: 8px;
  display: inline-block;
  font-size: 14px;
}

.forgot-password:hover {
  text-decoration: underline;
}

.signup-link {
  color: rgb(253, 182, 2);
  cursor: pointer;
  font-size: 14px;
}

.signup-link:hover {
  text-decoration: underline;
}

@media (max-width: 1024px) {
  .login-wrapper {
    flex-direction: column;
  }

  .login-container {
    width: 75%;
  }
}

@media (max-width: 768px) {
  .login-container {
    width: 85%;
    padding: 20px;
  }

  .login-title {
    margin-bottom: 10px;
    margin-top: 10px;
    font-size: 20px;
  }
}
