/* Register Page Container */
.oM-register-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f7f7f7;
  padding: 25px;
}

/* Form Container */
.oM-form-container {
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 30px;
  width: 100%;
  max-width: 400px;
}

/* Register Heading */
.oM-register-heading {
  font-size: 5px;
  color: #2d2d2d;
  margin-bottom: 20px;
  font-weight: 700;
  text-align: center;
}

/* Error and Success Messages */
.oM-register-message {
  font-size: 14px;
  margin-bottom: 10px;
}

.oM-register-message.error {
  color: red;
}

.oM-register-message.success {
  color: green;
}

/* Form Fields */
.oM-form-container div {
  margin-bottom: 10px;
}

.oM-form-container label {
  font-size: 14px;
  color: #555;
  margin-bottom: 5px;
  display: block;
}

.oM-form-container input,
.oM-form-container select {
  width: 100%;
  padding: 8px;
  font-size: 16px;
  border: 2px solid #ddd;
  border-radius: 8px;
  outline: none;
  transition: border 0.3s ease;
}

.oM-form-container input:focus,
.oM-form-container select:focus {
  border-color: #6c63ff;
}

.oM-errorInput {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}

/* Password Container */
.oM-password-container {
  position: relative;
}

.oM-signup-passowrd-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.oM-signup-passowrd-container input {
  width: 90%; /* Adjust width of the input */
  padding: 12px;
  font-size: 16px;
  border: 2px solid #ddd;
  border-radius: 8px;
  outline: none;
}

.oM-sigup-toggle-password {
  background-color: transparent;
  border: none;
  color: #6c63ff;
  cursor: pointer;
  font-size: 14px;
  padding: 8px;
  margin-left: 8px;
}

/* Register Button */
.oM-register-button {
  width: 100%;
  padding: 12px;
  background-color: #080808;
  color: white;
  font-size: 16px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.oM-register-button:hover {
  background-color: #5752e5;
}

/* Link to Login */
.oM-ptagtext {
  font-size: 14px;
  color: #555;
  text-align: center;
}
.oM-register-heading{
  font-size: 30px;
}

.oM-LoginClick {
  color: #ebcc07;
  cursor: pointer;
}

.oM-LoginClick:hover {
  text-decoration: underline;
}

/* OTP Form */
.otp-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.otp-form input {
  width: 100%;
  padding: 12px;
  font-size: 16px;
  border: 2px solid #ddd;
  border-radius: 8px;
}

.otp-form button {
  width: 100%;
  padding: 12px;
  background-color: #080808;
  color: white;
  font-size: 16px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.otp-form button:hover {
  background-color: #5752e5;
}

/* Responsive Design */
@media (max-width: 480px) {
  .oM-form-container {
    width: 90%;
    padding: 20px;
  }

  .oM-register-heading {
    font-size: 28px;
  }

  .oM-register-button {
    padding: 10px;
  }
}
