/* src/components/Help/Help.css */

.help-container {
    padding: 20px;
    text-align: center;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    margin: 20px auto;
  }
  
  .help-container h1 {
    font-size: 1.5rem;
    color: #333;
  }
  