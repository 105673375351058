/* ErrorPage.css */
.error-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
    padding: 20px;
    box-sizing: border-box;
    background-color: #f8f9fa;
  }
  
  h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
    color: #343a40;
  }
  
  p {
    font-size: 1.2rem;
    margin-bottom: 20px;
    color: #6c757d;
  }
  
  .go-home-button {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
  }
  
  .go-home-button:hover {
    background-color: #0056b3;
  }
  
  /* Responsive styles */
  @media (max-width: 768px) {
    h1 {
      font-size: 2rem;
    }
  
    p {
      font-size: 1rem;
    }
  
    .go-home-button {
      padding: 8px 16px;
      font-size: 0.9rem;
    }
  }
  
  @media (max-width: 480px) {
    h1 {
      font-size: 1.5rem;
    }
  
    p {
      font-size: 0.9rem;
    }
  
    .go-home-button {
      padding: 6px 12px;
      font-size: 0.8rem;
    }
  }
  