
.contact {
  padding-top: 40px;
  background-color: #f4f4f4;
  height: 90vh; 
  display: flex;
  justify-content: center;
  align-items: center;
}

.contact-section {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  padding-bottom: 7px;
}


.contact-form {
  max-width: 350px; 
  margin: 0 auto;
  background: white;
  border-radius: 10px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  padding: 20px;
}


.contact-form > h2 {
  text-align: center;
  font-size: 24px;
  margin-bottom: 20px;
}

.contact-input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-top: 6px;
  font-size: 0.9rem; 
}

.contact-input::placeholder {
  font-size: 12px; 
}

.contact-input-field > label {
  font-size: 14px; 
}

.contact-btn {
  padding: 10px;
  width: 100%;
  background-color: #02101D;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 0.9rem; 
  cursor: pointer;
}

.contact-btn:hover {
  background-color: #041f38;
}

.message-success {
  color: green;
  margin-top: 10px;
  font-size: 16px; 
}

.contact-image img {
  height: 78%;
  width: 75%;
  object-fit: contain;
  margin-left: -80px;
}

.contact-margin {
  margin-top: 50px;
}

@media (max-width: 768px) {
  .contact-form {
    margin-top: 30px;
    width: 85%;
    padding: 15px;
  }

  .contact-btn {
    font-size: 0.85rem;
  }
}

@media (max-width: 480px) {
  .container {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .contact-section {
    height: 85vh;
    width: 100%;
    max-width: 350px;
    padding-bottom: 60px;
  }

  .contact-form {
    margin-top: 50px;
    width: 95%;
    padding: 15px;
  }

  .contact-btn {
    font-size: 0.85rem;
  }

  .contact-image img {
    height: 70%;
    width: 90%;
    margin-left: 0;
  }
}
